<template>
<div>
  <div class="imageContentIt" id="mainStudy">
    <div class="imgContent">
      <p class="title-main">{{$t('it.title')}}</p>
      <p class="text-main">{{$t('it.text_1')}}</p>
      <p class="text-main">{{$t('it.text_2')}}</p>
      <p class="text-main">{{$t('it.text_3')}}</p>
      <v-card-actions class="text-center">
        <a class="caroselButton " href="https://www.codifylab.com/" target="_blank">{{$t('more')}}</a>
      </v-card-actions>
    </div>
  </div>
  <formAndQuestions></formAndQuestions>
</div>
</template>

<script>
import formAndQuestions from "@/components/FormAndQuestions";
export default {
  name: "ItEducation",
  components:{
    formAndQuestions
  }
}
</script>

<style scoped>
@media (max-width: 1000px) {
  .imgContent{
    top: 5% !important;
  }
  .title-main{
    font-size: 2.0rem;
  }
  .text-main{
    font-size: 1rem;
  }
  .imgContent{
    text-align: center;
    padding-right: 12px!important;
  }
  .caroselButton{
    margin: auto;
  }
}
.imgContent{
  top:20%;
}
.caroselButton{
text-decoration: none;
}
</style>